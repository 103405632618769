import React from "react";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import { Row, Col, Container, Image } from "react-bootstrap";

export default function MembershipInfo() {
  const membershipMenu = menuData.find((menu) => menu.title === "회원안내");

  return (
    <div>
      <SidebarLayout menu={membershipMenu}>
        <Container className="my-5">
          <Row className="align-items-center">
            {/* 텍스트 섹션 */}
            <Col md={8}>
              <p>
                우리 학회에 관심을 가져주셔서 감사합니다. 회원가입을 통해 다양한
                혜택을 누리실 수 있습니다. 회원으로 가입하시면 학술 세미나 및
                워크숍 참가, 학회 발간물 제공 등 다양한 활동에 참여하실 수
                있습니다.
              </p>

              <h5>회원 가입 방법</h5>
              <ul>
                <li>웹사이트 상단 메뉴에서 '회원가입'을 클릭합니다.</li>
                <li>필수 정보를 정확히 입력해주세요.</li>
                <li>가입 완료 후, 회원 승인 절차가 진행됩니다.</li>
              </ul>

              <h5>회원 혜택</h5>
              <ul>
                <li>학술 세미나 및 워크숍 무료 또는 할인 참가</li>
                <li>학회 발간물 우편 또는 이메일 제공</li>
                <li>회원 전용 정보 및 자료 열람</li>
                <li>학회 소속 전문가와의 네트워킹 기회</li>
              </ul>

              <p>
                회원 가입과 관련된 문의는 아래 이메일로 연락해주시기 바랍니다.
              </p>
              <p>
                <strong>문의 이메일: </strong>
                <a href="mailto:info@tourismkorea.or.kr">
                  info@tourismkorea.or.kr
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </SidebarLayout>
    </div>
  );
}
