// src/pages/login/Login.js
import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./login.css";
import axios from "axios";

export default function Login() {
  const [loginType, setLoginType] = useState("user");
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { username, password } = formData;

    if (!username || !password) {
      setError("아이디와 비밀번호를 입력해주세요.");
      return;
    }

    if (
      loginType === "admin" &&
      username === "admin" &&
      password === "admin123"
    ) {
      navigate("/admin-dashboard");
    } else if (
      loginType === "user" &&
      username === "user" &&
      password === "user123"
    ) {
      navigate("/user-dashboard");
    } else {
      setError("아이디 또는 비밀번호가 올바르지 않습니다.");
    }
  };

  return (
    <Container className="login-container">
      <Row className="justify-content-center">
        <Col md={6}>
          <h2 className="text-center mb-4">로그인</h2>

          {error && <Alert variant="danger">{error}</Alert>}

          <Form onSubmit={handleSubmit}>
            <ToggleButtonGroup
              type="radio"
              name="loginType"
              value={loginType}
              onChange={(val) => setLoginType(val)}
              className="mb-3 w-100"
            >
              <ToggleButton
                id="user-login"
                variant="outline-primary"
                value="user"
              >
                사용자 로그인
              </ToggleButton>
              <ToggleButton
                id="admin-login"
                variant="outline-secondary"
                value="admin"
              >
                관리자 로그인
              </ToggleButton>
            </ToggleButtonGroup>

            <Form.Group className="mb-3" controlId="formUsername">
              <Form.Label>아이디</Form.Label>
              <Form.Control
                type="text"
                placeholder="아이디를 입력하세요"
                name="username"
                value={formData.username}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>비밀번호</Form.Label>
              <Form.Control
                type="password"
                placeholder="비밀번호를 입력하세요"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mb-3">
              로그인
            </Button>

            {/* 홈으로 돌아가기 및 회원가입 버튼 */}
            <div className="d-flex justify-content-between">
              <Button variant="outline-secondary" onClick={() => navigate("/")}>
                홈으로 돌아가기
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => navigate("/register")}
              >
                회원가입
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}
