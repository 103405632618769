// src/pages/members/members.js
import React from "react";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import membersData from "../../data/membersData"; // 임원 데이터 가져오기
import { Card, Col, Row, Container } from "react-bootstrap";
import "./members.css"; // 스타일 불러오기

export default function Members() {
  const membersMenu = menuData.find((menu) => menu.title === "학회소개");

  return (
    <div>
      <SidebarLayout menu={membersMenu}>
        <Container>
          <Row className="gy-4">
            {membersData.map((member, index) => (
              <Col xs={12} key={index}>
                <Card className="member-card">
                  <Row className="g-0 align-items-center">
                    {/* 좌측 이미지 대체 박스 */}
                    <Col md={4} className="text-center">
                      <div className="image-placeholder" />
                    </Col>

                    {/* 우측 텍스트 섹션 */}
                    <Col md={8}>
                      <Card.Body>
                        <Card.Title className="mb-2">{member.name}</Card.Title>
                        <Card.Subtitle className="mb-3 text-muted">
                          {member.position}
                        </Card.Subtitle>
                        <Card.Text>{member.affiliation}</Card.Text>
                        <Card.Text>연락처: {member.contact}</Card.Text>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </SidebarLayout>
    </div>
  );
}
