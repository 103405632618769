// pages/NotFound.js
import React from "react";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>404</h1>
      <p style={styles.subtext}>페이지를 찾을 수 없습니다.</p>
      <p style={styles.description}>
        찾으시는 페이지가 존재하지 않습니다. 주소를 확인하거나 아래 버튼을
        클릭해 홈으로 돌아가세요.
      </p>
      <Link to="/" style={styles.button}>
        홈으로 돌아가기
      </Link>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "#f0f4f8",
    fontFamily: "Pretendard",
  },
  header: {
    fontSize: "5rem",
    marginBottom: "20px",
    color: "#2A4D8C",
  },
  subtext: {
    fontSize: "2rem",
    marginBottom: "10px",
    color: "#1A2E55",
  },
  description: {
    fontSize: "1.2rem",
    marginBottom: "30px",
    color: "#6c757d",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    color: "white",
    backgroundColor: "#3D9CEB",
    border: "none",
    borderRadius: "5px",
    textDecoration: "none",
    transition: "background-color 0.3s",
  },
  buttonHover: {
    backgroundColor: "#2A4D8C",
  },
};

export default NotFound;
