// src/components/Popup.js
import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./popup.css";

export default function Popup({ onClose, onHideForToday }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const hidePopupUntil = localStorage.getItem("hidePopupUntil");

    if (hidePopupUntil !== today) {
      setShow(true);
    }
  }, []);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleHideForToday = () => {
    const today = new Date().toISOString().split("T")[0];
    localStorage.setItem("hidePopupUntil", today);
    setShow(false);
    onHideForToday();
  };

  if (!show) return null; // 팝업이 비활성화되면 렌더링하지 않음

  return (
    <div className="popup-overlay" onClick={handleClose}>
      <div className="popup-content" onClick={(e) => e.stopPropagation()}>
        <div className="popup-placeholder">
          <h3>이벤트 배너</h3>
          <p>여기에는 이미지가 들어갈 자리입니다.</p>
        </div>
        <div className="popup-actions">
          <Button
            variant="outline-secondary"
            size="sm"
            onClick={handleHideForToday}
          >
            오늘 하루 보지 않기
          </Button>
          <Button variant="outline-secondary" size="sm" onClick={handleClose}>
            닫기
          </Button>
        </div>
      </div>
    </div>
  );
}
