const dummyNotices = [
  {
    id: 1,
    title: "2024년 학술 세미나 개최 안내",
    date: "2024-10-01",
    content:
      "2024년 학술 세미나가 오는 11월 12일부터 14일까지 서울 코엑스에서 개최됩니다. 이번 세미나에서는 최신 관광 산업의 발전 방향과 인공지능(AI)과의 융합을 주제로 다룰 예정입니다. 다양한 연구 발표와 전문가 강연이 준비되어 있으며, 참가자들은 연구 성과를 공유하고 네트워크를 형성할 기회를 갖게 됩니다.",
  },
  {
    id: 2,
    title: "관광 전문가 초청 강연",
    date: "2024-09-28",
    content:
      "국내외 관광 전문가들을 초청하여 관광 트렌드와 미래 비전을 소개합니다. 강연자들은 관광 산업의 변화와 지속 가능한 관광을 위한 접근 방식을 제안합니다.",
  },
  {
    id: 3,
    title: "2024년 정기 학회 공지",
    date: "2024-09-20",
    content:
      "이번 정기 학회에서는 관광 산업의 최신 연구와 성과를 발표합니다. 학계와 업계 전문가들이 한자리에 모여 학술 발표와 패널 토론을 진행합니다.",
  },
  {
    id: 4,
    title: "관광 취업센터 개소식",
    date: "2024-09-15",
    content:
      "청년 인재를 위한 관광 취업센터가 새롭게 문을 엽니다. 다양한 채용 정보 제공과 멘토링 프로그램을 통해 관광 분야 취업을 지원합니다.",
  },
  {
    id: 5,
    title: "장학금 수여식 안내",
    date: "2024-09-10",
    content:
      "2024년도 장학금 수여식이 10월 5일에 열립니다. 성적 우수자와 사회적 기여도가 큰 학생들을 대상으로 장학금이 수여됩니다.",
  },
  {
    id: 6,
    title: "회원 정기 총회 개최 안내",
    date: "2024-08-30",
    content:
      "회원 정기 총회가 오는 10월 25일에 열립니다. 이번 총회에서는 학회의 주요 활동 보고와 함께 새로운 프로젝트 계획이 논의될 예정입니다.",
  },
  {
    id: 7,
    title: "관광 데이터 분석 워크숍",
    date: "2024-08-20",
    content:
      "관광 데이터 분석을 주제로 한 워크숍이 개최됩니다. 이 워크숍에서는 최신 데이터 분석 기법을 활용한 관광 산업의 인사이트를 다룹니다.",
  },
  {
    id: 8,
    title: "회원 대상 특별 강좌 개설",
    date: "2024-08-15",
    content:
      "회원들을 위한 특별 강좌가 9월부터 개설됩니다. 이번 강좌에서는 마케팅과 관광 경영에 대한 심도 있는 강의가 제공됩니다.",
  },
  {
    id: 9,
    title: "관광 정책 연구 프로젝트 공모",
    date: "2024-08-05",
    content:
      "새로운 관광 정책 연구 프로젝트에 참여할 연구팀을 공모합니다. 선정된 팀에게는 연구 지원금이 제공됩니다.",
  },
  {
    id: 10,
    title: "관광 박람회 참가 안내",
    date: "2024-07-30",
    content:
      "학회는 2024년 관광 박람회에 참가합니다. 회원 여러분의 많은 참여를 부탁드립니다.",
  },
  {
    id: 11,
    title: "신규 회원 환영회 안내",
    date: "2024-07-15",
    content:
      "신규 회원을 위한 환영회가 개최됩니다. 학회 관계자와의 네트워킹 기회가 제공됩니다.",
  },
  {
    id: 12,
    title: "온라인 세미나 개최 안내",
    date: "2024-07-05",
    content:
      "온라인 세미나가 8월 10일에 개최됩니다. 이번 세미나는 비대면으로 진행되며, 다양한 주제의 발표가 예정되어 있습니다.",
  },
  {
    id: 13,
    title: "국제 관광 컨퍼런스 참가",
    date: "2024-06-30",
    content:
      "국제 관광 컨퍼런스에 학회 대표단이 참가합니다. 회원 여러분의 많은 관심을 부탁드립니다.",
  },
  {
    id: 14,
    title: "여름 캠프 프로그램 안내",
    date: "2024-06-25",
    content:
      "여름 방학 동안 청소년을 위한 관광 캠프가 개최됩니다. 참가 신청은 7월 말까지 받습니다.",
  },
  {
    id: 15,
    title: "회원 대상 설문조사 참여 요청",
    date: "2024-06-10",
    content:
      "회원 여러분의 의견을 반영하기 위한 설문조사에 참여해 주시기 바랍니다.",
  },
  {
    id: 16,
    title: "관광 학술지 논문 투고 안내",
    date: "2024-05-30",
    content:
      "관광 학술지에 논문을 투고할 수 있습니다. 제출 기한은 8월 15일까지입니다.",
  },
  {
    id: 17,
    title: "관광 산업 동향 보고서 발간",
    date: "2024-05-15",
    content:
      "관광 산업 동향 보고서가 발간되었습니다. 학회 웹사이트에서 확인하세요.",
  },
  {
    id: 18,
    title: "2024년 봄 소풍 안내",
    date: "2024-04-30",
    content: "회원들과 함께하는 봄 소풍이 5월 10일에 예정되어 있습니다.",
  },
  {
    id: 19,
    title: "관광 경영 전문가 초청 간담회",
    date: "2024-04-20",
    content: "관광 경영 전문가와의 간담회가 4월 25일에 열립니다.",
  },
  {
    id: 20,
    title: "회원 대상 체육대회 개최 안내",
    date: "2024-04-05",
    content: "회원 대상 체육대회가 4월 20일에 열립니다.",
  },
  {
    id: 21,
    title: "연말 기부 캠페인 시작",
    date: "2024-12-01",
    content:
      "연말 기부 캠페인이 시작되었습니다. 기부금은 장학금으로 사용됩니다.",
  },
  {
    id: 22,
    title: "관광 관련 도서 출판 기념회",
    date: "2024-11-25",
    content: "새로운 관광 관련 도서 출판 기념회가 열립니다.",
  },
  {
    id: 23,
    title: "2024년 송년회 안내",
    date: "2024-12-20",
    content: "2024년 송년회가 12월 30일에 열립니다. 많은 참여 부탁드립니다.",
  },
  {
    id: 24,
    title: "관광 전문가와의 간담회 안내",
    date: "2024-10-30",
    content: "관광 전문가와의 간담회가 11월 5일에 열립니다.",
  },
  {
    id: 25,
    title: "2024년 가을 워크숍 개최",
    date: "2024-11-10",
    content: "가을 워크숍이 11월 20일에 열립니다.",
  },
  {
    id: 26,
    title: "2024년 가을 워크숍 개최",
    date: "2024-11-10",
    content: "가을 워크숍이 11월 20일에 열립니다.",
  },
  {
    id: 27,
    title: "2024년 가을 워크숍 개최",
    date: "2024-11-10",
    content: "가을 워크숍이 11월 20일에 열립니다.",
  },
  {
    id: 28,
    title: "2024년 가을 워크숍 개최",
    date: "2024-11-10",
    content: "가을 워크숍이 11월 20일에 열립니다.",
  },
  {
    id: 29,
    title: "2024년 가을 워크숍 개최",
    date: "2024-11-10",
    content: "가을 워크숍이 11월 20일에 열립니다.",
  },
  {
    id: 30,
    title: "2024년 가을 워크숍 개최",
    date: "2024-11-10",
    content: "가을 워크숍이 11월 20일에 열립니다.",
  },
];

export default dummyNotices;
