// src/pages/preparing/Preparing.js
import React from "react";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import { Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export default function Preparing() {
  const location = useLocation();

  // 현재 경로에 맞는 메뉴를 동적으로 찾기
  const currentMenu = menuData.find((menu) =>
    menu.items.some((item) => location.pathname.startsWith(item.path))
  );

  // 현재 경로에 해당하는 중분류 항목을 찾기
  const currentItem = currentMenu.items.find((item) =>
    location.pathname.startsWith(item.path)
  );

  return (
    <div>
      <SidebarLayout menu={currentMenu} subTitle={currentItem?.label}>
        <Container className="text-center my-5">
          <Row className="justify-content-center">
            <Col md={8}>
              <h2>준비 중입니다.</h2>
              <p>해당 페이지는 현재 준비 중입니다. 곧 만나보실 수 있습니다!</p>
              <p>
                문의가 필요하신 경우 info@tourismkorea.or.kr로 연락해 주세요.
              </p>
            </Col>
          </Row>
        </Container>
      </SidebarLayout>
    </div>
  );
}
