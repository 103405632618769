import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./footer.css"; // 스타일 파일 추가

function Footer() {
  return (
    <footer className="footer bg-light">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h5 className="footer-title">사단법인 한국관광산업포럼</h5>
            <p>대표자 : 윤대순</p>
            <p>사업자등록번호 : 110-82-11834</p>
            <p>
              주소 : 서울특별시 강서구 공항대로 269-15, 3층 315-A4호 (마곡동,
              마곡힐스테이트에코마곡)
            </p>
            <p>문의 : info@tourismkorea.or.kr</p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col className="text-center">
            <p>&copy; 2024 한국관광산업포럼. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
