// src/pages/organization/organization.js
import React from "react";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import { Row, Col, Card } from "react-bootstrap";
import "./organization.css"; // 조직도 CSS

export default function Organization() {
  const organizationMenu = menuData.find((menu) => menu.title === "학회소개");

  return (
    <div>
      <SidebarLayout menu={organizationMenu}>
        <Row className="align-items-center"></Row>

        {/* 조직도 이미지 섹션 */}
        <Row className="justify-content-center mb-5">
          <Col md={10} className="text-center">
            <div className="placeholder-image">
              조직도 이미지가 들어갈 자리입니다.
            </div>
          </Col>
        </Row>

        {/* 조직 설명 섹션 */}
        <Row>
          <Col>
            <Card className="p-4">
              <Card.Body>
                <Card.Title className="mb-3">조직 소개</Card.Title>
                <Card.Text>
                  우리 학회의 조직은 다양한 분야의 전문가들로 구성되어 있으며,
                  각 부서가 상호 협력하여 학회의 목표를 달성합니다. 회장,
                  부회장, 총무 등 주요 임원진과 더불어 학술위원회, 교육위원회,
                  연구위원회 등의 부서가 운영되고 있습니다.
                </Card.Text>
                <Card.Text>
                  각 부서는 학문적 연구와 산업 발전을 위해 노력하며, 회원들의
                  성장을 도모하는 다양한 활동을 주관합니다. 학회는 또한 관광
                  분야의 인재 양성을 위해 교육 및 장학 프로그램을 운영하고
                  있습니다.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </SidebarLayout>
    </div>
  );
}
