// src/pages/directions/Directions.js
import React from "react";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import { Row, Col } from "react-bootstrap";

export default function Directions() {
  const directionsMenu = menuData.find((menu) => menu.title === "학회소개");

  return (
    <div>
      <SidebarLayout menu={directionsMenu}>
        <Row className="align-items-center">
          {/* 지도 섹션 */}
          <Col md={12} className="text-center mb-4">
            <iframe
              title="오시는 길"
              src={`https://www.google.com/maps/embed/v1/place?key=YOUR_GOOGLE_MAPS_API_KEY&q=마곡역`}
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
            ></iframe>
          </Col>

          {/* 주소 및 교통편 정보 */}
          <Col md={12}>
            <h5>주소</h5>
            <p>서울특별시 강서구 마곡동 마곡역</p>

            <h5>대중교통 이용 시</h5>
            <p>5호선 마곡역 1번 출구에서 도보 5분</p>

            <h5>문의</h5>
            <p>info@tourismkorea.or.kr | 02-1234-5678</p>
          </Col>
        </Row>
      </SidebarLayout>
    </div>
  );
}
