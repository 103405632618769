import React from "react";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import { Row, Col } from "react-bootstrap";
import "./greeting.css"; // CSS 파일 추가

export default function Greeting() {
  const greetingMenu = menuData.find((menu) => menu.title === "학회소개");

  return (
    <div>
      <SidebarLayout menu={greetingMenu}>
        <Row className="align-items-center">
          {/* 이미지 대체 회색 박스 섹션 */}
          <Col md={4} className="text-center">
            <div className="image-placeholder" />
          </Col>
          <Col md={8}>
            <p>
              안녕하세요! 우리 학회를 찾아주셔서 진심으로 감사드립니다. 우리
              학회는 오랜 역사를 가지고 있으며, 학문적 연구와 사회 발전에
              기여하고 있습니다. 여러분과 함께 새로운 길을 만들어 나갈 수 있기를
              기대합니다.
            </p>
            <p>
              저희 학회는 다양한 학문적 활동을 통해 지식의 교류를 촉진하고,
              사회와 과학 발전에 중요한 역할을 하고 있습니다. 앞으로도 많은
              관심과 참여 부탁드립니다.
            </p>
            <p>감사합니다.</p>
            <p>학회장 홍길동</p>
          </Col>
        </Row>
      </SidebarLayout>
    </div>
  );
}
