const membersData = [
  {
    position: "회장",
    name: "홍길동",
    affiliation: "한국대학교 관광학과",
    contact: "010-1234-5678",
    image: "https://via.placeholder.com/300", // 임시 이미지 URL
  },
  {
    position: "부회장",
    name: "김철수",
    affiliation: "서울관광연구소",
    contact: "010-8765-4321",
    image: "https://via.placeholder.com/300", // 임시 이미지 URL
  },
  {
    position: "총무",
    name: "이영희",
    affiliation: "한국문화재단",
    contact: "010-1111-2222",
    image: "https://via.placeholder.com/300", // 임시 이미지 URL
  },
  {
    position: "감사",
    name: "박민수",
    affiliation: "대한관광협회",
    contact: "010-3333-4444",
    image: "https://via.placeholder.com/300", // 임시 이미지 URL
  },
];

export default membersData;
