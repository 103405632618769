import React from "react";
import { Carousel, Container, Row, Col, Button, Card } from "react-bootstrap";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import Popup from "../../components/popup";
import "./home.css";

export default function Home() {
  return (
    <div>
      <Popup
        onClose={() => console.log("팝업이 닫혔습니다.")}
        onHideForToday={() => console.log("오늘 하루 보지 않기 설정됨.")}
      />
      {/* 배너 캐러셀 */}
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block carousel-img"
            src="/images/banner/banner1.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>학술 세미나 안내</h3>
            <p>2024년 11월, 다양한 학술 세미나가 개최됩니다.</p>
            <Button variant="primary">자세히 보기</Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block carousel-img"
            src="/images/banner/banner2.jpg"
            alt="Second slide"
          />
          <Carousel.Caption>
            <h3>관광 전문가 양성 프로그램</h3>
            <p>미래 관광 전문가를 위한 맞춤형 교육.</p>
            <Button variant="primary">프로그램 확인</Button>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block carousel-img"
            src="/images/banner/banner3.jpg"
            alt="Third slide"
          />
          <Carousel.Caption>
            <h3>회원 가입 혜택</h3>
            <p>지금 가입하고 다양한 혜택을 누리세요!</p>
            <Button variant="primary">회원가입</Button>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>

      {/* 서비스 소개 섹션 */}
      <Container className="my-5">
        <h2 className="text-center mb-4">우리 학회의 주요 서비스</h2>
        <Row className="g-4">
          {["학술 세미나", "관광 취업센터", "회원 전용 혜택"].map(
            (title, index) => (
              <Col md={4} key={index}>
                <Card className="service-card hover-effect">
                  <div className="image-placeholder" />
                  <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>
                      {title}에 대한 설명이 이곳에 들어갑니다. 더 알아보세요.
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            )
          )}
        </Row>
      </Container>

      {/* 게시판 미리보기 섹션 */}
      <Container className="my-5 preview-section">
        <Row className="g-4">
          <Col md={6}>
            <div className="preview-box">
              <h4>공지사항</h4>
              <ul className="preview-list">
                <li>2024 춘계학술대회 감사의 글</li>
                <li>한국관광연구학회 춘계학술대회 성료</li>
                <li>한국관광연구학회 대학생 아이디어 공모...</li>
              </ul>
              <div className="text-end">
                <a href="/notice">바로가기 &gt;</a>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="preview-box">
              <h4>학술대회 안내</h4>
              <ul className="preview-list">
                <li>2024동계정기학술대회 개최 안내 _ 대학생공모전...</li>
                <li>2024동계정기학술대회 개최 안내 _ 논문발표 신청...</li>
                <li>2024년도 (사)한국관광연구학회 정기학술대회...</li>
              </ul>
              <div className="text-end">
                <a href="/qna">바로가기 &gt;</a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

      {/* CTA 섹션 */}
      <Container fluid className="cta-section text-center text-white">
        <h2>회원가입하고 다양한 혜택을 누리세요!</h2>
        <p>관광 산업의 미래를 함께 만들어 갈 인재를 기다립니다.</p>
        <Button variant="light" size="lg">
          지금 회원가입 하기
        </Button>
      </Container>
    </div>
  );
}
