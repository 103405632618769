// src/pages/ci/CI.js
import React from "react";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import { Row, Col, Image, Button } from "react-bootstrap";
import "./ci.css"; // CI 페이지 스타일

export default function CI() {
  const ciMenu = menuData.find((menu) => menu.title === "학회소개");

  return (
    <div className="page-content">
      <SidebarLayout menu={ciMenu}>
        <Row className="align-items-center">
          {/* CI 이미지 섹션 */}
          <Col md={6} className="text-center">
            <Image
              src="https://via.placeholder.com/400x200"
              alt="학회 CI 이미지"
              fluid
              rounded
              className="ci-image"
            />
          </Col>

          {/* CI 설명 섹션 */}
          <Col md={6}>
            <p>
              본 학회의 CI는 관광 산업의 성장과 혁신을 상징하며, 신뢰와 협력을
              기반으로 한 네트워크를 표현합니다. 학회의 상징 색상은
              **#1A6DFF**로, 미래지향적이며 개방적인 가치를 담고 있습니다.
            </p>
            <p>
              CI는 다양한 미디어에서 일관되게 사용되며, 학회의 정체성을
              효과적으로 전달합니다. 아래 버튼을 통해 CI 파일을 다운로드하여
              사용할 수 있습니다.
            </p>
            <Button
              href="/files/ci-logo.png"
              download
              variant="primary"
              className="mt-3"
            >
              CI 파일 다운로드
            </Button>
          </Col>
        </Row>
      </SidebarLayout>
    </div>
  );
}
