import React from "react";
import "./underConstruction.css";

const UnderConstruction = () => {
  return (
    <div className="under-construction">
      <h1 className="header">🚧 홈페이지 개편중입니다 🚧</h1>
      <p className="text">현재 홈페이지를 개편중입니다. 곧 찾아뵙겠습니다!</p>
      <p className="text">
        문의사항은{" "}
        <a href="mailto:info@tourismkorea.or.kr">info@tourismkorea.or.kr</a>으로
        부탁드립니다.
      </p>
    </div>
  );
};

export default UnderConstruction;
