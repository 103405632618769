// src/pages/notice/Notice.js
import React, { useState } from "react";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import { Table, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom"; // 상세 페이지로 이동을 위한 Link
import "./notice.css";

// 임시 공지사항 데이터 (더미 데이터 30개)
const dummyNotices = Array.from({ length: 30 }, (_, i) => ({
  id: i + 1,
  title: `공지사항 ${i + 1}: 주요 업데이트 및 공지사항`,
  date: `2024-10-${String(i + 1).padStart(2, "0")}`,
  views: Math.floor(Math.random() * 500), // 임의의 조회수 생성
}));

const ITEMS_PER_PAGE = 10; // 페이지당 항목 수

export default function Notice() {
  const noticeMenu = menuData.find((menu) => menu.title === "학회소식");
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태

  const totalPages = Math.ceil(dummyNotices.length / ITEMS_PER_PAGE); // 총 페이지 수

  // 현재 페이지에 해당하는 공지사항 계산
  const currentNotices = dummyNotices.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // 페이지 변경 핸들러
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <SidebarLayout menu={noticeMenu}>
        <Table className="notice-table" striped hover>
          <thead>
            <tr>
              <th className="text-center" style={{ width: "10%" }}>
                번호
              </th>
              <th>제목</th>
              <th className="text-center" style={{ width: "15%" }}>
                날짜
              </th>
              <th className="text-center" style={{ width: "10%" }}>
                조회수
              </th>
            </tr>
          </thead>
          <tbody>
            {currentNotices.map((notice, index) => (
              <tr key={notice.id}>
                <td className="text-center">
                  {(currentPage - 1) * ITEMS_PER_PAGE + index + 1}
                </td>
                <td>
                  <Link to={`/notice/${notice.id}`} className="notice-link">
                    {notice.title}
                  </Link>
                </td>
                <td className="text-center">{notice.date}</td>
                <td className="text-center">{notice.views}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* 페이지네이션 */}
        <Pagination className="justify-content-center mt-4">
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </SidebarLayout>
    </div>
  );
}
