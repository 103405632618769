import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import SidebarLayout from "../../components/sidebarLayout";
import NavigationBar from "../../components/navbar";
import Footer from "../../components/footer";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기

export default function Terms() {
  const memberMenu = menuData.find((menu) => menu.title === "회원안내");

  return (
    <div>
      <SidebarLayout menu={memberMenu}>
        <Container className="my-5">
          <Row className="mb-4">
            <Col>
              <h5>제 1조 (목적)</h5>
              <p>
                본 약관은 학회와 회원 간의 권리, 의무 및 책임 사항을 규정함을
                목적으로 합니다.
              </p>

              <h5>제 2조 (회원 가입)</h5>
              <p>
                1. 회원이 되고자 하는 자는 본 약관에 동의하고, 정해진 양식에
                따라 회원 정보를 제출해야 합니다.
                <br />
                2. 학회는 정당한 이유가 없는 한 회원 가입을 승인합니다.
              </p>

              <h5>제 3조 (회원의 권리 및 의무)</h5>
              <p>
                1. 회원은 학회가 제공하는 각종 혜택과 정보를 받을 권리가
                있습니다.
                <br />
                2. 회원은 본 학회의 명예를 손상시키는 행위를 해서는 안 됩니다.
              </p>

              <h5>제 4조 (회원 탈퇴 및 자격 상실)</h5>
              <p>
                1. 회원은 언제든지 탈퇴를 요청할 수 있으며, 학회는 즉시 탈퇴를
                처리합니다.
                <br />
                2. 회원이 본 약관을 위반할 경우, 학회는 회원 자격을 제한하거나
                박탈할 수 있습니다.
              </p>

              <h5>제 5조 (약관의 개정)</h5>
              <p>
                본 약관은 필요 시 개정될 수 있으며, 개정된 약관은 공지 후 효력이
                발생합니다.
              </p>

              <h5>제 6조 (기타)</h5>
              <p>
                본 약관에 명시되지 않은 사항에 대해서는 관련 법령 및 관례에
                따릅니다.
              </p>
            </Col>
          </Row>
        </Container>
      </SidebarLayout>
    </div>
  );
}
