// src/pages/regulations/regulations.js
import React from "react";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import { Accordion, Card } from "react-bootstrap";

export default function Regulations() {
  const regulationsMenu = menuData.find((menu) => menu.title === "학회소개");

  return (
    <div>
      <SidebarLayout menu={regulationsMenu}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>제 1 조 (명칭)</Accordion.Header>
            <Accordion.Body>
              본 학회는 한국관광산업포럼이라 칭하며, 영문으로는 "Tourism Korea
              Forum"이라 한다.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>제 2 조 (목적)</Accordion.Header>
            <Accordion.Body>
              본 학회는 관광 산업의 발전과 관련된 연구, 교육, 네트워킹 등을 통해
              관광 산업의 성장에 기여함을 목적으로 한다.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>제 3 조 (회원 자격)</Accordion.Header>
            <Accordion.Body>
              본 학회의 회원은 관광 산업에 관심이 있거나 관련 연구를 수행하는
              자로 구성되며, 회원 가입 절차를 거쳐야 한다.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>제 4 조 (조직 구성)</Accordion.Header>
            <Accordion.Body>
              학회의 조직은 회장, 부회장, 총무, 위원회 등으로 구성되며, 각
              역할과 책임은 내부 규정에 따른다.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="4">
            <Accordion.Header>제 5 조 (회의)</Accordion.Header>
            <Accordion.Body>
              학회는 정기 총회와 임시 총회를 개최하며, 중요한 의사 결정을 위해
              회원들의 의견을 수렴한다.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="5">
            <Accordion.Header>제 6 조 (재정)</Accordion.Header>
            <Accordion.Body>
              학회의 재정은 회원 회비, 기부금, 후원금, 기타 수익으로 구성되며,
              투명하게 관리된다.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="6">
            <Accordion.Header>제 7 조 (부칙)</Accordion.Header>
            <Accordion.Body>
              본 정관에 명시되지 않은 사항은 관례에 따르며, 필요한 경우 이사회의
              결의를 통해 결정한다.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </SidebarLayout>
    </div>
  );
}
