import React from "react";
import { Table, Container, Row, Col, Button } from "react-bootstrap";
import SidebarLayout from "../../components/sidebarLayout";
import NavigationBar from "../../components/navbar";
import Footer from "../../components/footer";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import { useNavigate } from "react-router-dom"; // 페이지 이동을 위한 hook

export default function MembershipFees() {
  const memberMenu = menuData.find((menu) => menu.title === "회원안내");
  const navigate = useNavigate(); // 페이지 이동을 위한 hook

  const handleRegisterClick = () => {
    navigate("/register"); // 회원가입 페이지로 이동
  };

  return (
    <div>
      <SidebarLayout menu={memberMenu}>
        <Container className="my-5">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>구분</th>
                <th>가입비</th>
                <th>연 회비</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>A등급</td>
                <td>100,000원</td>
                <td>300,000원</td>
              </tr>
              <tr>
                <td>B등급</td>
                <td>50,000원</td>
                <td>200,000원</td>
              </tr>
              <tr>
                <td>C등급</td>
                <td>30,000원</td>
                <td>100,000원</td>
              </tr>
            </tbody>
          </Table>

          <p className="text-muted mt-4">
            ※ 회비는 매년 1월에 납부해야 하며, 미납 시 혜택이 제한될 수
            있습니다.
          </p>

          <hr />

          {/* 입금계좌 정보 및 문의처 */}
          <Row className="mt-4">
            <Col>
              <h5>■ 입금계좌 정보 및 문의처</h5>
              <p>
                <strong>예금주:</strong> 한국학회연구회 ㅣ{" "}
                <strong>은행명:</strong> 국민은행 ㅣ <strong>계좌번호:</strong>{" "}
                123-456-7890
              </p>
              <ul className="list-unstyled">
                <li>
                  • <strong>납부방식:</strong> 일시납
                </li>
                <li>
                  • 통장입금 시 송금자명은 반드시 <strong>회원명</strong>으로
                  납부하시기 바랍니다.
                </li>
                <li>
                  • <strong>기타 문의사항:</strong> 사무국장 이영호 (Mobile:
                  010-1234-5678)
                </li>
                <li>• 간사 김민주 (Mobile: 010-9876-5432)</li>
              </ul>
            </Col>
          </Row>

          {/* 회원가입 버튼 */}
          <Row className="mt-5 justify-content-center">
            <Col md={4}>
              <Button
                variant="primary"
                className="w-100"
                onClick={handleRegisterClick}
              >
                회원가입 하기
              </Button>
            </Col>
          </Row>
        </Container>
      </SidebarLayout>
    </div>
  );
}
