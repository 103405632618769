import "./App.css";
import "./index.css";
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/home/home";
import Greeting from "./pages/greeting/greeting";
import Overview from "./pages/overview/overView";
import NotFound from "./pages/notfound/notFound";
import UnderConstruction from "./pages/underconstruction/underConstruction";
import Members from "./pages/members/members";
import Organization from "./pages/organization/organization";
import Regulations from "./pages/regulations/regulations";
import CI from "./pages/ci/ci";
import Directions from "./pages/directions/directions";
import Notice from "./pages/notices/notice";
import NoticeDetail from "./pages/notices/noticeDetail";
import QnA from "./pages/qna/qna";
import QnADetail from "./pages/qna/qnaDetail";
import MembershipInfo from "./pages/membershipinfo/membershipInfo";
import Register from "./pages/register/register";
import Terms from "./pages/terms/terms";
import PaymentInfo from "./pages/paymentinfo/paymentInfo";
import Preparing from "./pages/preparing/preparing";
import NavigationBar from "./components/navbar";
import Footer from "./components/footer";
import Login from "./pages/login/login";

function App() {
  const location = useLocation();

  // 네비게이션 바가 필요 없는 페이지 목록
  const noNavbarPaths = ["/login"];

  return (
    <div className="app-container">
      {/* 조건부 네비게이션 바 */}
      {!noNavbarPaths.includes(location.pathname) && <NavigationBar />}

      <div className="page-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/greeting" element={<Greeting />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/members" element={<Members />} />
          <Route path="/organization" element={<Organization />} />
          <Route path="/regulations" element={<Regulations />} />
          <Route path="/ci" element={<CI />} />
          <Route path="/directions" element={<Directions />} />
          <Route path="/notice" element={<Notice />} />
          <Route path="/notice/:id" element={<NoticeDetail />} />
          <Route path="/qna" element={<QnA />} />
          <Route path="/qna/:id" element={<QnADetail />} />
          <Route path="/membership-info" element={<MembershipInfo />} />
          <Route path="/register" element={<Register />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/payment-info" element={<PaymentInfo />} />
          <Route path="/seminars" element={<Preparing />} />
          <Route path="/projects" element={<Preparing />} />
          <Route path="/education" element={<Preparing />} />
          <Route path="/scholarships" element={<Preparing />} />
          <Route path="/job-center" element={<Preparing />} />
          <Route path="/other-business" element={<Preparing />} />
          <Route path="/industry-news" element={<Preparing />} />
          <Route path="/login" element={<Login />} />
          <Route path="/under-construction" element={<UnderConstruction />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>

      <Footer />
    </div>
  );
}

export default App;
