import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import dummyQnA from "../../data/dummyQnA"; // 더미 데이터 가져오기
import { Container, Row, Col, Button, ListGroup } from "react-bootstrap";

export default function QnADetail() {
  const { id } = useParams(); // 현재 Q&A ID 가져오기
  const navigate = useNavigate(); // 페이지 이동을 위한 hook
  const qnaMenu = menuData.find((menu) => menu.title === "학회소식");
  const qna = dummyQnA.find((q) => q.id === parseInt(id, 10));

  // 공지사항 상세페이지에선 중분류 제목을 직접 지정
  const subTitle = "Q&A";

  return (
    <div>
      <SidebarLayout menu={qnaMenu} subTitle={subTitle}>
        <Container className="my-5">
          {/* 제목 및 작성자, 작성일자 */}
          <Row className="align-items-center mb-3">
            <Col>
              <h1 className="qna-title">{qna.title}</h1>
            </Col>
            <Col className="text-end text-muted small">
              <p>작성자: {qna.author}</p>
              <p>작성일: {qna.date}</p>
            </Col>
          </Row>

          {/* 내용 */}
          <Row className="mb-5">
            <Col>
              <p>{qna.content}</p>
            </Col>
          </Row>

          {/* 관리자 댓글 섹션 */}
          <Row className="mb-3">
            <Col>
              <h5>관리자 댓글</h5>
              <ListGroup>
                {qna.comments.map((comment, index) => (
                  <ListGroup.Item key={index}>
                    <strong>{comment.author}</strong>: {comment.text}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>

          {/* 목록으로 돌아가기 버튼 */}
          <Button variant="primary" onClick={() => navigate("/qna")}>
            목록으로 돌아가기
          </Button>
        </Container>
      </SidebarLayout>
    </div>
  );
}
