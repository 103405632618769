const menuData = [
  {
    title: "학회소개",
    items: [
      { path: "/greeting", label: "인사말" },
      { path: "/overview", label: "학회개요" },
      { path: "/members", label: "임원명단" },
      { path: "/organization", label: "조직도" },
      { path: "/regulations", label: "정관" },
      { path: "/ci", label: "학회CI" },
      { path: "/directions", label: "오시는 길" },
    ],
  },
  {
    title: "학회소식",
    items: [
      { path: "/notice", label: "공지사항" },
      { path: "/qna", label: "Q&A" },
    ],
  },
  {
    title: "주요사업",
    items: [
      { path: "/seminars", label: "학술 세미나" },
      { path: "/projects", label: "연구 프로젝트" },
      { path: "/education", label: "관광 전문가 양성 교육" },
      { path: "/scholarships", label: "장학사업" },
      { path: "/job-center", label: "관광 취업센터" },
      { path: "/other-business", label: "기타사업" },
    ],
  },
  {
    title: "회원안내",
    items: [
      { path: "/membership-info", label: "회원가입 안내" },
      { path: "/register", label: "회원가입" },
      { path: "/terms", label: "회원약관" },
      { path: "/payment-info", label: "회원납부 안내" },
    ],
  },
  {
    title: "업계소식",
    items: [{ path: "/industry-news", label: "업계소식" }],
  },
];

export default menuData;
