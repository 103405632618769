import React from "react";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import { Row, Col } from "react-bootstrap";
import "./overView.css"; // CSS 파일 추가

export default function Overview() {
  const overviewMenu = menuData.find((menu) => menu.title === "학회소개");

  return (
    <div>
      <SidebarLayout menu={overviewMenu}>
        <Row className="align-items-center">
          {/* 이미지 대체 회색 박스 섹션 */}
          <Col md={6} className="text-center">
            <div className="image-placeholder" />
          </Col>
          <Col md={6}>
            <p>
              우리 학회는 다양한 학문적 연구와 사회적 기여를 통해 관광 산업의
              발전을 도모하고 있습니다. 학회는 연구자와 전문가들 간의 지식
              교류를 촉진하며, 관광 산업의 미래를 이끌어 나가고자 노력합니다.
            </p>
            <p>
              학회 활동은 학술 세미나, 연구 프로젝트, 교육 프로그램 등을
              포함하며, 이를 통해 학문적 성과를 사회에 환원하고 있습니다. 또한,
              청년 인재 양성을 위한 장학사업과 취업 지원 센터를 운영하여
              실질적인 도움을 제공하고자 합니다.
            </p>
            <p>
              앞으로도 저희 학회는 혁신적이고 창의적인 연구를 통해 관광 산업의
              성장을 견인하며, 회원들과 함께 발전해 나가겠습니다.
            </p>
          </Col>
        </Row>
      </SidebarLayout>
    </div>
  );
}
