import React from "react";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./sidebarLayout.css"; // CSS 파일 추가

export default function SidebarLayout({ menu, children, subTitle }) {
  const location = useLocation(); // 현재 경로 확인

  // 중분류 제목 설정: 동적 경로에서는 전달된 subTitle 사용
  const currentItem = menu.items.find(
    (item) => item.path === location.pathname
  );
  const titleToDisplay =
    subTitle || (currentItem ? currentItem.label : menu.title);

  return (
    <Container className="my-5 sidebar-layout">
      <Row>
        {/* 좌측 사이드바 */}
        <Col md={3}>
          <h4 className="mb-3 text-center">{menu.title}</h4>
          <hr />
          <ListGroup variant="flush">
            {menu.items.map((item) => (
              <ListGroup.Item
                key={item.path}
                action
                href={item.path}
                active={location.pathname === item.path}
              >
                {item.label}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>

        {/* 우측 메인 콘텐츠 */}
        <Col md={9}>
          <Row className="mb-4 text-center">
            <Col>
              <h4>{titleToDisplay}</h4> {/* 동적/정적 경로에 맞는 제목 표시 */}
              <hr />
            </Col>
          </Row>

          {/* 메인 콘텐츠 */}
          <div className="content">{children}</div>
        </Col>
      </Row>
    </Container>
  );
}
