const dummyQnA = [
  {
    id: 1,
    title: "회원 가입 관련 문의",
    author: "홍길동",
    date: "2024-10-01",
    content: "회원 가입 시 발생하는 오류에 대해 문의드립니다.",
    comments: [
      {
        author: "관리자",
        text: "안녕하세요, 오류를 해결했습니다. 다시 시도해 주세요.",
      },
    ],
  },
  {
    id: 2,
    title: "학술 세미나 신청 방법",
    author: "김철수",
    date: "2024-09-28",
    content: "학술 세미나에 어떻게 신청하나요?",
    comments: [
      { author: "관리자", text: "학회 홈페이지에서 신청 가능합니다." },
    ],
  },
  {
    id: 3,
    title: "장학금 지원 관련 문의",
    author: "이영희",
    date: "2024-09-20",
    content: "장학금 지원 자격 조건이 어떻게 되나요?",
    comments: [
      {
        author: "관리자",
        text: "지원 자격은 학회 홈페이지 공지사항을 참고해 주세요.",
      },
    ],
  },
];

export default dummyQnA;
