import React, { useState } from "react";
import { Form, Button, Container, Alert, Modal } from "react-bootstrap";
import NavigationBar from "../../components/navbar";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import "./register.css"; // CSS 파일 추가

export default function Register() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, setError] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState(false); // 성공 모달 상태

  const memberMenu = menuData.find((menu) => menu.title === "회원안내");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, confirmPassword } = formData;

    if (!name || !email || !password || !confirmPassword) {
      setError("모든 필드를 입력해주세요.");
      return;
    }
    if (password !== confirmPassword) {
      setError("비밀번호가 일치하지 않습니다.");
      return;
    }

    setError("");
    console.log("회원가입 정보:", formData);
    setShowSuccessModal(true);
  };

  const handleModalClose = () => {
    setShowSuccessModal(false);
    window.location.reload();
  };

  return (
    <div>
      <SidebarLayout menu={memberMenu}>
        <Container className="my-5">
          {error && <Alert variant="danger">{error}</Alert>}

          <Form onSubmit={handleSubmit} className="register-form">
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>
                이름 <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="이름을 입력하세요"
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>
                이메일 <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                placeholder="이메일을 입력하세요"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>
                비밀번호 <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="비밀번호를 입력하세요"
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formConfirmPassword">
              <Form.Label>
                비밀번호 확인 <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="password"
                placeholder="비밀번호를 다시 입력하세요"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
              />
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100">
              회원가입
            </Button>
          </Form>

          <Modal show={showSuccessModal} onHide={handleModalClose}>
            <Modal.Header closeButton>
              <Modal.Title>회원가입 신청 완료</Modal.Title>
            </Modal.Header>
            <Modal.Body>회원가입 신청이 완료되었습니다!</Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleModalClose}>
                확인
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </SidebarLayout>
    </div>
  );
}

// import React, { useState } from "react";
// import { Form, Button, Container, Alert, Modal } from "react-bootstrap";
// import SidebarLayout from "../../components/sidebarLayout";
// import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
// import axios from "axios"; // Axios 가져오기
// import "./register.css"; // CSS 파일 추가

// export default function Register() {
//   const [formData, setFormData] = useState({
//     name: "",
//     email: "",
//     password: "",
//     confirmPassword: "",
//   });
//   const [error, setError] = useState("");
//   const [showSuccessModal, setShowSuccessModal] = useState(false);
//   const [loading, setLoading] = useState(false); // 로딩 상태 추가

//   const memberMenu = menuData.find((menu) => menu.title === "회원안내");

//   // 입력 필드 변경 핸들러
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   // 회원가입 요청 핸들러 (서버로 데이터 전송)
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const { name, email, password, confirmPassword } = formData;

//     // 입력값 유효성 검사
//     if (!name || !email || !password || !confirmPassword) {
//       setError("모든 필드를 입력해주세요.");
//       return;
//     }
//     if (password !== confirmPassword) {
//       setError("비밀번호가 일치하지 않습니다.");
//       return;
//     }

//     setError("");
//     setLoading(true); // 로딩 시작

//     try {
//       // 서버에 회원가입 데이터 전송
//       const response = await axios.post(
//         "http://localhost:8080/api/register", // 서버 URL
//         formData,
//         { headers: { "Content-Type": "application/json" } }
//       );

//       if (response.status === 201) {
//         setShowSuccessModal(true); // 회원가입 성공 시 모달 표시
//       }
//     } catch (error) {
//       if (error.response) {
//         setError(error.response.data.message || "회원가입에 실패했습니다.");
//       } else {
//         setError("서버와의 통신에 문제가 발생했습니다.");
//       }
//     } finally {
//       setLoading(false); // 로딩 종료
//     }
//   };

//   const handleModalClose = () => {
//     setShowSuccessModal(false);
//     window.location.reload(); // 페이지 새로고침
//   };

//   return (
//     <div>
//       <SidebarLayout menu={memberMenu}>
//         <Container className="my-5">
//           {error && <Alert variant="danger">{error}</Alert>}

//           <Form onSubmit={handleSubmit} className="register-form">
//             <Form.Group className="mb-3" controlId="formName">
//               <Form.Label>
//                 이름 <span className="text-danger">*</span>
//               </Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="이름을 입력하세요"
//                 name="name"
//                 value={formData.name}
//                 onChange={handleChange}
//               />
//             </Form.Group>

//             <Form.Group className="mb-3" controlId="formEmail">
//               <Form.Label>
//                 이메일 <span className="text-danger">*</span>
//               </Form.Label>
//               <Form.Control
//                 type="email"
//                 placeholder="이메일을 입력하세요"
//                 name="email"
//                 value={formData.email}
//                 onChange={handleChange}
//               />
//             </Form.Group>

//             <Form.Group className="mb-3" controlId="formPassword">
//               <Form.Label>
//                 비밀번호 <span className="text-danger">*</span>
//               </Form.Label>
//               <Form.Control
//                 type="password"
//                 placeholder="비밀번호를 입력하세요"
//                 name="password"
//                 value={formData.password}
//                 onChange={handleChange}
//               />
//             </Form.Group>

//             <Form.Group className="mb-3" controlId="formConfirmPassword">
//               <Form.Label>
//                 비밀번호 확인 <span className="text-danger">*</span>
//               </Form.Label>
//               <Form.Control
//                 type="password"
//                 placeholder="비밀번호를 다시 입력하세요"
//                 name="confirmPassword"
//                 value={formData.confirmPassword}
//                 onChange={handleChange}
//               />
//             </Form.Group>

//             <Button
//               variant="primary"
//               type="submit"
//               className="w-100"
//               disabled={loading}
//             >
//               {loading ? "회원가입 중..." : "회원가입"}
//             </Button>
//           </Form>

//           {/* 회원가입 성공 모달 */}
//           <Modal show={showSuccessModal} onHide={handleModalClose}>
//             <Modal.Header closeButton>
//               <Modal.Title>회원가입 신청 완료</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>회원가입 신청이 완료되었습니다!</Modal.Body>
//             <Modal.Footer>
//               <Button variant="primary" onClick={handleModalClose}>
//                 확인
//               </Button>
//             </Modal.Footer>
//           </Modal>
//         </Container>
//       </SidebarLayout>
//     </div>
//   );
// }
