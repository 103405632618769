import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import dummyNotices from "../../data/dummyNotices"; // 임시 데이터 가져오기
import { Button, Row, Col } from "react-bootstrap";
import "./noticeDetail.css";

export default function NoticeDetail() {
  const { id } = useParams(); // 현재 공지사항 ID 가져오기
  const navigate = useNavigate(); // 페이지 이동을 위한 hook
  const noticeMenu = menuData.find((menu) => menu.title === "학회소식");
  const notice = dummyNotices.find((n) => n.id === parseInt(id, 10));

  const currentIndex = dummyNotices.findIndex((n) => n.id === parseInt(id, 10));
  const prevNotice = dummyNotices[currentIndex - 1];
  const nextNotice = dummyNotices[currentIndex + 1];

  // 공지사항 상세페이지에선 중분류 제목을 직접 지정
  const subTitle = "공지사항";

  return (
    <div>
      <SidebarLayout menu={noticeMenu} subTitle={subTitle}>
        <div className="notice-detail">
          {/* 제목 및 작성일자 */}
          <Row className="align-items-center mb-3">
            <Col>
              <h1 className="notice-title">{notice.title}</h1>
            </Col>
            <Col className="text-end text-muted small">
              <p>작성일: {notice.date}</p>
            </Col>
          </Row>

          {/* 내용 */}
          <Row className="mb-5">
            <Col>
              <p>{notice.content}</p>
            </Col>
          </Row>

          {/* 이전글/다음글 버튼 */}
          <Row className="justify-content-between">
            <Col>
              {prevNotice && (
                <Button
                  variant="outline-primary"
                  onClick={() => navigate(`/notice/${prevNotice.id}`)}
                >
                  이전 글: {prevNotice.title}
                </Button>
              )}
            </Col>
            <Col className="text-end">
              {nextNotice && (
                <Button
                  variant="outline-primary"
                  onClick={() => navigate(`/notice/${nextNotice.id}`)}
                >
                  다음 글: {nextNotice.title}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </SidebarLayout>
    </div>
  );
}
