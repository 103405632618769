// src/pages/qna/QnA.js
import React, { useState } from "react";
import { Table, Pagination } from "react-bootstrap";
import { Link } from "react-router-dom";
import SidebarLayout from "../../components/sidebarLayout";
import Footer from "../../components/footer";
import NavigationBar from "../../components/navbar";
import menuData from "../../data/menuData"; // 메뉴 데이터 가져오기
import dummyQnA from "../../data/dummyQnA"; // 더미 데이터 가져오기
import "./qna.css"; // 스타일 가져오기

const ITEMS_PER_PAGE = 10; // 페이지당 항목 수

export default function QnA() {
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
  const qnaMenu = menuData.find((menu) => menu.title === "학회소식");

  const totalPages = Math.ceil(dummyQnA.length / ITEMS_PER_PAGE); // 총 페이지 수

  // 현재 페이지에 해당하는 Q&A 계산
  const currentQnA = dummyQnA.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  // 페이지 변경 핸들러
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <SidebarLayout menu={qnaMenu}>
        <Table className="qna-table" striped hover>
          <thead>
            <tr>
              <th className="text-center" style={{ width: "10%" }}>
                번호
              </th>
              <th>제목</th>
              <th className="text-center" style={{ width: "15%" }}>
                작성자
              </th>
              <th className="text-center" style={{ width: "15%" }}>
                작성일자
              </th>
              <th className="text-center" style={{ width: "10%" }}>
                조회수
              </th>
            </tr>
          </thead>
          <tbody>
            {currentQnA.map((qna, index) => (
              <tr key={qna.id}>
                <td className="text-center">
                  {(currentPage - 1) * ITEMS_PER_PAGE + index + 1}
                </td>
                <td>
                  <Link to={`/qna/${qna.id}`} className="qna-link">
                    {qna.title}
                  </Link>
                </td>
                <td className="text-center">{qna.author}</td>
                <td className="text-center">{qna.date}</td>
                <td className="text-center">{qna.views}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* 페이지네이션 */}
        <Pagination className="justify-content-center mt-4">
          <Pagination.First
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          />
          <Pagination.Prev
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          />
          {Array.from({ length: totalPages }, (_, i) => (
            <Pagination.Item
              key={i + 1}
              active={i + 1 === currentPage}
              onClick={() => handlePageChange(i + 1)}
            >
              {i + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          />
          <Pagination.Last
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          />
        </Pagination>
      </SidebarLayout>
    </div>
  );
}
