// src/components/navbar.js
import React, { useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import menuData from "../data/menuData"; // menuData 가져오기

function NavigationBar() {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleMouseEnter = (index) => setActiveDropdown(index);
  const handleMouseLeave = () => setActiveDropdown(null);

  return (
    <Navbar bg="white" expand="lg">
      <Container>
        <Navbar.Brand href="/">
          <div
            style={{
              width: "200px",
              height: "50px",
              backgroundColor: "#3D9CEB",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            Logo
          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {menuData.map((menu, index) => (
              <NavDropdown
                key={index}
                title={<span>{menu.title}</span>}
                id={`nav-dropdown-${index}`}
                show={activeDropdown === index}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {menu.items.map((item, idx) => (
                  <NavDropdown.Item key={idx} href={item.path}>
                    {item.label}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            ))}
          </Nav>

          <Nav className="ms-auto">
            <Nav.Link href="/login">로그인</Nav.Link>
            <Nav.Link href="/register">회원가입</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
